import './Route';
import './App.css';
import {motion} from "framer-motion"
import React, { useState, useEffect } from 'react';
import About from './about';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import Video from './assets/img/demo-screen.mp4';
import img from './assets/img/sirkui-svg.png';
import img2 from './assets/img/app-store.png';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import imgD from './assets/img/app-store-badge.svg';
import imgsefed from './assets/img/Ethiopian_hand_woven_circle_plate.png';



const textVariant = {
  initial:{
    x:-500,
    opacity:0,
  },
  animate:{
    x:0,
    opacity:1,
  transition:{
    duration:1.4,
    staggerChildren: 0.1,
  }
  }

}

const fourDecsVariant = {
  initial:{
    scale:0.7,
    opacity:0,
  },
  animate:{
    scale:1,
    opacity:1,
  transition:{
    duration:0.89,
    staggerChildren:0.77,
  }
  }

}

function App() {

  const [downloadLinks, setDownloadLinks] = useState([]);
   const [fetchedData, setFetchedData] = useState([]);
  const [counter, setCounter] = useState(0);
  const [countapp, setCountapp] = useState(0);
  const [fileName, setFileName] =useState([]);
  const [successm, setSuccessm] =useState('');

    const ApiKey = "a325-71b0-227a-2012"


    const handleContextMenu = (e) => {
      e.preventDefault(); // Prevent the default right-click context menu
    };
    useEffect(() => {

    // Fetch the download links from the backend
    fetch('https://staging.appstore.et/api/profiles', {
      headers: {
        'X-API-Key': ApiKey,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
       

        if (data && data.length > 0) {
         
          // Assuming 'name' is the property you want to display in downloadLinks
          setDownloadLinks(data.map(item => item.downloadLink));
          setFileName(data.map(item => item.name));
          setFetchedData(data)
        } else {
          console.error("Failed to fetch downloadLinks. Server response:", data);
        }
      })
      .catch(error => {
        console.error('Error during fetch:', error);
      });
  }, []); // The empty dependency array ensures this effect runs once on component mount

// function tests () {fetchedData.map((a)=>{
//  console.log("test..8" +a.item)
//  })}
 
//  tests()
const close =() =>{
  setSuccessm('');
}
  const handleDownload = (link , a) => {
  let linkDownload ;
  let downloadname ;
    fetchedData.map((a)=>{
 console.log(a.item)
 if(a.item == link)
 {
 linkDownload = a.downloadLink
 downloadname= a.name
 }
 
 })
    // Create a temporary <a> element to initiate the download
   // const downloadLink = document.createElement('a');
    //downloadLink.href = link;
   // const filenames = fileName[a];
    //downloadLink.download = filenames;
     //downloadLink.name = filenames;
     
      const downloadLink = document.createElement('a');
     
    downloadLink.download = downloadname;
    downloadLink.href = linkDownload;
   
 
    
     //downloadLink.download ="test";
   console.log("downloadname " + downloadname)
    downloadLink.click();
  };


 
  useEffect(() => {
    updateCounter();
  }, []);

  const updateCounter = () => {
    fetch('https://staging.appstore.et/api/count')
      .then(response => response.json())
      .then(data => {
        setCounter(data.count);
      })
      .catch(error => {
        console.error('Failed to fetch download counter.', error);
      });
  };

  const increaseCounter = () => {
    fetch('https://staging.appstore.et/api/increase', { method: 'POST' })
      .then(() => {
        updateCounter();
      })
      .catch(error => {
        console.error('Failed to increase download counter.', error);
      });
    };
     
  useEffect(() => {
    fetchUserCount();
  }, []);

  const fetchUserCount = () => {
    fetch('https://staging.appstore.et/api/count1')
      .then(response => response.json())
      .then(data => {
        setCountapp(data.count);
      })
      .catch(error => {
        console.error('Failed to fetch user count.', error);
      });
  };

  const increaseUserCount = () => {
    fetch('https://staging.appstore.et/api/increase1', { method: 'POST' })
      .then(() => {
        fetchUserCount();
      })
      .catch(error => {
        console.error('Failed to increase user count.', error);
      });
  };


  
  const [fullname, setFullname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()

    const Feedback = { fullname, email, phone, message }

    const response = await fetch('https://staging.appstore.et/api/feedback', {
      method: 'POST',
      body: JSON.stringify(Feedback),
      headers: {
        'Content-Type': 'application/json',
         'x-api-key':"res.status(200).json(workouts)@"
      }
    })
    const json = await response.json()

    if (!response.ok) {
      setError(json.error)
    }
    if (response.ok) {
      setError(null)
      setFullname('')
      setEmail('')
      setPhone('')
      setMessage('')

      console.log('new feedback added:', json)
      setSuccessm("Thanks For Give Us Feedback!!!");
          
    }

  }
  //localization
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="description" content="" />
      <meta name="author" content="" />
      <title>appstore.et</title>
      <link rel="icon" type="image/x-icon" href="assets/favicon.ico" />
      {/* Bootstrap icons*/}
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css"
        rel="stylesheet"
      />
      {/* Google fonts*/}
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Newsreader:ital,wght@0,600;1,600&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,500;0,600;0,700;1,300;1,500;1,600;1,700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,400;1,400&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      {/* Core theme CSS (includes Bootstrap)*/}
      <link href="css/styles.css" rel="stylesheet" />
      {/* Navigation*/}
      <nav
        className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm"
        id="mainNav"
      >
        <div className="container px-5">
          <a className="navbar-brand fw-bold" href="https://appstore.et">
          <img src={img}  style={{maxHeight:30}}/>  {t('navSirkuni')}
          </a>
        
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
          
            <i className="bi-list" />
          </button>
          
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
              <li className="nav-item">
                <a className="nav-link me-lg-3" href="#features">
                {t('features')}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link me-lg-3" href="#download">
                {t('downloadnav')}
                </a>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link" style={{ marginTop: '-8px' }} >
                  <a className="nav-link me-lg-3" href="#" >
                  {t('termandpolicy')}
                  </a>
                </Link>
              </li>
            </ul>
            <button
              className="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0"
              data-bs-toggle="modal"
              data-bs-target="#feedbackModal"
            >
              <span className="d-flex align-items-center">
                <i className="bi-chat-text-fill me-2" />
                <span className="small">{t('sendfeedback')}</span>
              </span>
            </button>
            
          </div>
          <div style={{paddingLeft:"20px", '@media (max-width: 500px)': {
            paddingLeft:"50px",
          },}}> 
          <select 
        id="language"
        onChange={handleLanguageChange}
        value={i18n.language}
        style={{
          backgroundColor:"#29AACE",
          color:"white",
          padding: '10px',
          fontSize: '14px',
          borderRadius: '25px',
          border: '1px solid #ccc',
          cursor: 'pointer',
          width: '100%',
          maxWidth: '80px',
          margin: '0 auto',
          paddingRight: '30px', // Adjust as needed
      
          // Responsive styles
          '@media (max-width: 600px)': {
            maxWidth: '100%',
      paddingRight: '10px', // Adjust as needed
      backgroundSize: '20px', // Adjust as needed
      backgroundPosition: 'right 5px center',
      textIndent: '-9999px', // Hide text
          },
        }}
      >
        <option value="en">English</option>
        <option value="am">አማርኛ (Amharic)</option>
        {/* Add more language options as needed */}
      </select>
      </div>
        </div>
       
      </nav>
      {/* Mashead header*/}
      <header className="masthead">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              {/* Mashead text and app badges*/}





              <motion.div variants={textVariant} className="mb-5 mb-lg-0 text-center text-lg-start" initial="initial" animate="animate">
                <motion.h1 variants={textVariant} className="display-1 lh-1 mb-3">{t('welcome')}</motion.h1>
                <motion.p variants={textVariant} className="lead fw-normal text-muted mb-5">
                {t('welcomepara')}
                </motion.p>
                <motion.div variants={textVariant} className="d-flex flex-column flex-lg-row align-items-center">
                  <motion.a variants={textVariant} href="#download">
                    {" "}
                    <motion.button variants={textVariant} className="btnd">
                      <i style={{ fontSize: 25 }} className="fa fa-download" />

                      <b style={{ fontSize: 17 }}> {t('downloadbtn')}</b>
                    </motion.button>
                  </motion.a>
                  {/* <a class="me-lg-3 mb-4 mb-lg-0" href="#!"><img class="app-badge" src="assets/img/google-play-badge.svg" alt="..." /></a> */}
                  {/* <a href="#!">
                  <img
                    className="app-badge"
                    src={imgD}
                    alt="..."
                  />
                </a> */}
                </motion.div>
              </motion.div>





            </div>
            <div className="col-lg-6">
              {/* Masthead device mockup feature*/}
              <div className="masthead-device-mockup">
                <svg
                  className="circle"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <linearGradient
                      id="circleGradient"
                      gradientTransform="rotate(45)"
                    >
                      <stop className="gradient-start-color" offset="0%" />
                      <stop className="gradient-end-color" offset="100%" />
                    </linearGradient>
                  </defs>
                  <circle cx={50} cy={50} r={50} />
                </svg>
                <svg
                  className="shape-1 d-none d-sm-block"
                  viewBox="0 0 240.83 240.83"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="-32.54"
                    y="78.39"
                    width="305.92"
                    height="84.05"
                    rx="42.03"
                    transform="translate(120.42 -49.88) rotate(45)"
                  />
                  <rect
                    x="-32.54"
                    y="78.39"
                    width="305.92"
                    height="84.05"
                    rx="42.03"
                    transform="translate(-49.88 120.42) rotate(-45)"
                  />
                </svg>
                <svg
                  className="shape-2 d-none d-sm-block"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx={50} cy={50} r={50} />
                </svg>
                <div className="device-wrapper">
                  <div
                    className="device"
                    data-device="iPhoneX"
                    data-orientation="portrait"
                    data-color="black"
                  >
                    <div className="screen bg-black">
                      {/* PUT CONTENTS HERE:*/}
                      {/* * * This can be a video, image, or just about anything else.*/}
                      {/* * * Set the max width of your media to 100% and the height to*/}
                      {/* * * 100% like the demo example below.*/}
                      {/* <img src="assets/img/sirkui-svg.png" alt="..." style="width: 30px; " /> */}
                      <video
                        muted
                        autoPlay
                        loop
                        onContextMenu={handleContextMenu}
                        style={{ maxWidth: "100%", height: "100%" }}
                        playsInline  // Add this attribute
                      // Add this attribute to show video controls
                      >
                        <source src={Video} type="video/mp4" />

                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* Quote/testimonial aside*/}
      <aside className="text-center bg-gradient-primary-to-secondary">
        <div className="container px-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-xl-8">
              <div className="h2 fs-1 text-white mb-4">
              {t('centermessage')}
              </div>
              {/* <img src="assets/img/tnw-logo.svg" alt="..." style="height: 3rem" /> */}
            </div>
          </div>
        </div>
      </aside>
      {/* App features section*/}
      <section id="features">
        <div className="container px-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-8 order-lg-1 mb-5 mb-lg-0">
             
             
             
            <motion.div variants={fourDecsVariant} className="container-fluid px-5" initial="initial" whileInView="animate">
                <motion.div variants={fourDecsVariant} className="row gx-5">
                  <motion.div variants={fourDecsVariant} className="col-md-6 mb-5">
                    {/* Feature item*/}
                    <motion.div variants={fourDecsVariant} className="text-center">
                      <motion.i variants={fourDecsVariant} className="bi-phone icon-feature text-gradient d-block mb-3" />
                      <motion.h3 variants={fourDecsVariant} className="font-alt">{t('foralldevice')}</motion.h3>
                      <motion.p variants={fourDecsVariant} className="text-muted mb-0">
                      {t('foralldevicedec')}
                      </motion.p>
                    </motion.div>
                  </motion.div>
                  <motion.div variants={fourDecsVariant} className="col-md-6 mb-5">
                    {/* Feature item*/}
                    <motion.div variants={fourDecsVariant}className="text-center">
                      <motion.i variants={fourDecsVariant} className="bi-camera icon-feature text-gradient d-block mb-3" />
                      <motion.h3 variants={fourDecsVariant} className="font-alt">{t('voicevideocall')}</motion.h3>
                      <motion.p  variants={fourDecsVariant} className="text-muted mb-0">
                      {t('voicevideocalldec')}
                      </motion.p>
                    </motion.div>
                  </motion.div>
                </motion.div>
                <motion.div variants={fourDecsVariant}className="row">
                  <motion.div variants={fourDecsVariant} className="col-md-6 mb-5 mb-md-0">
                    {/* Feature item*/}
                    <motion.div variants={fourDecsVariant} className="text-center">
                      <motion.i variants={fourDecsVariant} className="bi-gift icon-feature text-gradient d-block mb-3" />
                      <motion.h3 variants={fourDecsVariant} className="font-alt">{t('freetouse')}</motion.h3>
                      <motion.p variants={fourDecsVariant} className="text-muted mb-0">
                      {t('freetousedec')}
                      </motion.p>
                    </motion.div>
                  </motion.div>
                  <motion.div variants={fourDecsVariant} className="col-md-6">
                    {/* Feature item*/}
                    <motion.div variants={fourDecsVariant} className="text-center">
                      <i className="bi-patch-check icon-feature text-gradient d-block mb-3" />
                      <motion.h3 variants={fourDecsVariant} className="font-alt"> {t('endtoend')}</motion.h3>
                      <motion.p variants={fourDecsVariant} className="text-muted mb-0">
                      {t('endtoenddec')}
                      </motion.p>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </motion.div>





            </div>
            <div className="col-lg-4 order-lg-0">
              {/* Features section device mockup*/}
              <div className="features-device-mockup">

                {/* <img
                className="circle"
                    src={imgsefed}
                    alt="..."
                  /> */}
                <svg
                  className="circle"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <linearGradient
                      id="circleGradient"
                      gradientTransform="rotate(45)"
                    >
                      <stop className="gradient-start-color" offset="0%" />
                      <stop className="gradient-end-color" offset="100%" />
                    </linearGradient>
                  </defs>
                  <circle cx={50} cy={50} r={50} />
                </svg>
                <svg
                  className="shape-1 d-none d-sm-block"
                  viewBox="0 0 240.83 240.83"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="-32.54"
                    y="78.39"
                    width="305.92"
                    height="84.05"
                    rx="42.03"
                    transform="translate(120.42 -49.88) rotate(45)"
                  />
                  <rect
                    x="-32.54"
                    y="78.39"
                    width="305.92"
                    height="84.05"
                    rx="42.03"
                    transform="translate(-49.88 120.42) rotate(-45)"
                  />
                </svg>
                <svg
                  className="shape-2 d-none d-sm-block"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx={50} cy={50} r={50} />
                </svg>
                <div className="device-wrapper">
                  <div
                    className="device"
                    data-device="iPhoneX"
                    data-orientation="portrait"
                    data-color="black"
                  >
                    <div className="screen bg-black">
                      {/* PUT CONTENTS HERE:*/}
                      {/* * * This can be a video, image, or just about anything else.*/}
                      {/* * * Set the max width of your media to 100% and the height to*/}
                      {/* * * 100% like the demo example below.*/}
                      <video
                        muted
                        autoPlay
                        loop
                        onContextMenu={handleContextMenu}
                        style={{ maxWidth: "100%", height: "100%" }}
                        playsInline  // Add this attribute
                      // Add this attribute to show video controls
                      >
                        <source src={Video} type="video/mp4" />

                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Basic features section*/}
      <section className="bg-light">
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
            <div className="col-12 col-lg-5">
              <h2 className="display-4 lh-1 mb-4"> {t('navSirkuni')}</h2>
              <p className="lead fw-normal text-muted mb-5 mb-lg-0">
              {t('termsmessage')}
              </p>
            </div>
            <div className="col-sm-8 col-md-7">
              <img src={img2} alt="..." style={{ maxWidth: "100%", height: "auto", borderRadius: '40px' }} />

              <div className="px-5 px-sm-0" />
            </div>
          </div>
        </div>
      </section>
      {/* Call to action section*/}
      <section className="cta">
        <div className="cta-content">
          <div className="container px-5">
            <p className="text-white display-1 lh-1 mb-4">
            {t('staysafe')}
            </p>
            <a
              className="btn btn-outline-light py-3 px-4 rounded-pill"
              href="https://support.appstore.et"
              target="_blank"
            >
               {t('forsupport')}
            </a>
          </div>
        </div>
      </section>
      {/* App badge section*/}
      <section className="bg-gradient-primary-to-secondary" id="download">
        <div className="container px-5">
          <h2 className="text-center text-white font-alt mb-4">
          {t('downloadmobile')}{" "}
            <i style={{ fontSize: 25 }} className="fa fa-download" />
          </h2>
          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center" onClick={increaseCounter}>
            <a className="me-lg-3 mb-4 mb-lg-0" >
                 <button className="btd" onClick={() =>{     handleDownload("apk",0)
           
              }}>
                <i style={{ fontSize: 25 }} className="fa fa-android" />
                <i> </i>
                Sirkuni-Andriod.apk
              </button>
            </a>
          </div>
        </div>
        <br />
        <div className="container px-5">
          <h2 className="text-center text-white font-alt mb-4">
          {t('downloaddesktop')}{" "}
            <i style={{ fontSize: 25 }} className="fa fa-download" />
          </h2>
          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center" >
            <a className="me-lg-3 mb-4 mb-lg-0" onClick={increaseUserCount}>
              <button className="btd" onClick={() => {
               
              handleDownload("exe",1)
             
              }}>
                {" "}
                <i style={{ fontSize: 25 }} className="fa fa-windows" /> <i></i>
                Sirkuni-desktop.exe
              </button>

            </a>
            <a className="me-lg-3 mb-4 mb-lg-0" onClick={increaseUserCount}>
            <button className="btd" onClick={() =>{handleDownload("deb",2)
              
              }}>
                {" "}
                <i style={{ fontSize: 25 }} className="fa fa-linux" /> <i></i>
                Sirkuni-desktop.deb
              </button>
            </a>
            <a className="me-lg-3 mb-4 mb-lg-0" onClick={increaseUserCount}>
            <button className="btd" onClick={() =>{handleDownload("dmg",3)
              
              }}>
                {" "}
                <i style={{ fontSize: 25 }} className="fa fa-apple" /> <i></i>
                Sirkuni-desktop.dmg
              </button>
            </a>

          </div>
        </div>
      </section>
      {/* Footer*/}
      <footer className="bg-black text-center py-5">
        <div className="container px-5">
          <div className="text-white-50 small">
            <div className="mb-2">© {t('insa')} {t('allright')}</div>
            <Link to="/about" >
                  <a >{t('privacy')} </a>
                </Link>
            <span className="mx-1">·</span>
            <Link to="/about" >
            <a >{t('term')} </a>
                </Link>
            
            <span className="mx-1">·</span>
            <Link to="/about" >
            <a >{t('term')} </a>
                </Link>
                <span className="mx-1">·</span>
            <a href="https://support.appstore.et"> {t('faq')} </a>
          </div>
        </div>
      </footer>
      {/* Feedback Modal*/}
      <div
        className="modal fade"
        id="feedbackModal"
        tabIndex={-1}
        aria-labelledby="feedbackModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-gradient-primary-to-secondary p-4">
              <h5
                className="modal-title font-alt text-white"
                id="feedbackModalLabel"
              >
                Send feedback
              </h5>
              <button
                className="btn-close btn-close-white"
                type="button"
                onClick={close} 
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body border-0 p-4">
              {/* * * * * * * * * * * * * * * **/}
              {/* * * SB Forms Contact Form * **/}
              {/* * * * * * * * * * * * * * * **/}
              {/* This form is pre-integrated with SB Forms.*/}
              {/* To make this form functional, sign up at*/}
              {/* to get an API token!*/}
              <form id="contactForm" data-sb-form-api-token="API_TOKEN" onSubmit={handleSubmit}>
                {/* Name input*/}
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="name"
                    type="text"
                    onChange={(e) => setFullname(e.target.value)}
                    value={fullname}
                    placeholder="Enter your name..."
                    data-sb-validations="required"
                  />
                  <label htmlFor="name">Full name</label>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="name:required"
                  >
                    A name is required.
                  </div>
                </div>
                {/* Email address input*/}
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="name@example.com"
                    data-sb-validations="required,email"
                  />
                  <label htmlFor="email">Email address</label>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="email:required"
                  >
                    An email is required.
                  </div>
                  <div className="invalid-feedback" data-sb-feedback="email:email">
                    Email is not valid.
                  </div>
                </div>
                {/* Phone number input*/}
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="phone"
                    type="tel"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    placeholder="(123) 456-7890"
                    data-sb-validations="required"
                  />
                  <label htmlFor="phone">Phone number</label>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="phone:required"
                  >
                    A phone number is required.
                  </div>
                </div>
                {/* Message input*/}
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    id="message"
                    type="text"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    placeholder="Enter your message here..."
                    style={{ height: "10rem" }}
                    data-sb-validations="required"
                    defaultValue={""}
                  />
                  <label htmlFor="message">Message</label>
                  <div
                    className="invalid-feedback"
                    data-sb-feedback="message:required"
                  >
                    A message is required.
                  </div>
                </div>
                {/* Submit success message*/}
                {/**/}
                {/* This is what your users will see when the form*/}
                {/* has successfully submitted*/}
                <div className="d-none" id="submitSuccessMessage">
                  <div className="text-center mb-3">
                    <div className="fw-bolder">{error && <div className="error">{error}</div>}</div>
                   
                    <br />
                    <a href="https://appstore.et">https://appstore.et</a>
                  </div>
                </div>
                <div className="d-none" id="submitErrorMessage">
                  <div className="text-center text-danger mb-3">
                    Error sending message!
                  </div>
                </div>
                {/* Submit Button*/}
                <div className="d-grid">
                <br/>
               <br/>
               <center><b>{successm && <div className="error">{successm}</div>}</b></center> 
               <br/>
               <center>{error && <div className="error">{error}</div>}</center> 
               <br/>
               <br/>
                  <button
                    className="btn btn-primary rounded-pill btn-lg "
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      <div
        className="modal fade"
        id="mydiv"
        tabIndex={-1}
        aria-labelledby="feedbackModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header bg-gradient-primary-to-secondary p-4">
              <h5
                className="modal-title font-alt text-white"
                id="feedbackModalLabel"
              >
                Sirkuni Team
              </h5>
              <button
                className="btn-close btn-close-white"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body border-0 p-4">
              {/* * * * * * * * * * * * * * * **/}
              {/* * * SB Forms Contact Form * **/}
              {/* * * * * * * * * * * * * * * **/}
              {/* This form is pre-integrated with SB Forms.*/}
              {/* To make this form functional, sign up at*/}
              {/* to get an API token!*/}
              
            </div>
          </div>
        </div>
      </div>

      {/* Bootstrap core JS*/}
      {/* Core theme JS*/}
      {/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * **/}
      {/* * *                               SB Forms JS                               * **/}
      {/* * * Activate your form at https://startbootstrap.com/solution/contact-forms * **/}
      {/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * **/}
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
      </Helmet>
      <Helmet>
        <script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script>
      </Helmet>
    </>
  );
}

export default App;
