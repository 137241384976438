import React from 'react';
import "./index.css";
import Home from "./main";
import About from "./about";
import { Route, Routes } from 'react-router-dom';
import './i18n'; 



function App() {
  return (
   <>
   <Routes>
     <Route path="/" element={<Home/>} />
     <Route path="/about" element={<About/>} />
    
   </Routes>
   </>
   
   
  );
}

export default App;