import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import img from './assets/img/sirkui-svg.png';
import { useTranslation } from 'react-i18next';
function about(){

    //localization
    // const { t, i18n } = useTranslation();

    // const handleLanguageChange = (e) => {
    //   const selectedLanguage = e.target.value;
    //   i18n.changeLanguage(selectedLanguage);
    // };
    return(
        <>
  <meta charSet="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, shrink-to-fit=no"
  />
  <meta name="description" content="" />
  <meta name="author" content="" />
  <title>appstore.et</title>
  <link rel="icon" type="image/x-icon" href="assets/favicon.ico" />
  {/* Bootstrap icons*/}
  <link
    href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css"
    rel="stylesheet"
  />
  {/* Google fonts*/}
  <link rel="preconnect" href="https://fonts.gstatic.com" />
  <link
    href="https://fonts.googleapis.com/css2?family=Newsreader:ital,wght@0,600;1,600&display=swap"
    rel="stylesheet"
  />
  <link
    href="https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,500;0,600;0,700;1,300;1,500;1,600;1,700&display=swap"
    rel="stylesheet"
  />
  <link
    href="https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,400;1,400&display=swap"
    rel="stylesheet"
  />
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
  />
  {/* Core theme CSS (includes Bootstrap)*/}
  <link href="css/styles.css" rel="stylesheet" />
  {/* Navigation*/}
  <nav
    className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm"
    id="mainNav"
  >
    <div className="container px-5">
      <a className="navbar-brand fw-bold" href="https://appstore.et">
          <img src={img}  style={{maxHeight:30}}/>  Sirkuni
          </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        Menu
        <i className="bi-list" />
      </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
        <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
          <li className="nav-item">
          <Link to="/"  className="nav-link" style={{marginTop:'-8px'}} >
            <a className="nav-link me-lg-3" href="index.html">
             Back
            </a>
            </Link>
          </li>
        
        </ul>
        
      </div>
    </div>
  </nav>
  {/* Mashead header*/}
  <header className="masthead">
    <div className="container px-5">
      <div className="row gx-5 align-items-center">
        <div >
        {/* className="col-lg-6" */}
          {/* Mashead text and app badges*/}
          <div className="mb-5 mb-lg-0 text-center text-lg-start">
            <h1 className=" lh-1 mb-3"> Sirkuni Terms &amp; Privacy Policy</h1>
            <p className="lead fw-normal">
              Sirkuni is designed to provide secure instant messaging. Sirkuni
              messages and calls cannot be accessed by us or other third parties
              because they are always end-to-end encrypted, private, and secure.
              Our Terms of Service and Privacy Policy are available below.
            </p>
            <h1 className=" lh-1 mb-3"> Terms of Service </h1>
            Sirkuni utilizes state-of-the-art security and end-to-end encryption
            to provide private messaging, Internet calling, and other services
            to users worldwide. You agree to our Terms of Service (“Terms”) by
            installing or using our apps, services, or website (together,
            “Services”).
            <h1 className=" lh-1 mb-3"> About our services</h1>
            Minimum Age. You must be at least 13 years old to use our Services.
            The minimum age to use our Services without parental approval may be
            higher in your home country. Account Registration. To create an
            account, you must register for our Services using your phone number.
            You agree to receive text messages and phone calls (from us or our
            third-party providers) with verification codes to register for our
            Services. Privacy of user data. Sirkuni does not sell, rent or
            monetize your personal data or content in any way – ever. Please
            read our Privacy Policy to understand how we safeguard the
            information you provide when using our Services. For the purpose of
            operating our Services, you agree to our data practices as described
            in our Privacy Policy, Examples would be Third Party Providers
            sending you a verification code and processing your support tickets.
            Software. In order to enable new features and enhanced
            functionality, you consent to downloading and installing updates to
            our Services. Fees and Taxes. You are responsible for data and
            mobile carrier fees and taxes associated with the devices on which
            you use our Services.
            <h1 className=" lh-1 mb-3"> Using Sirkuni </h1>
            Our Terms and Policies. You must use our Services according to our
            Terms and posted policies. If we disable your account for a
            violation of our Terms, you will not create another account without
            our permission. Legal and Acceptable Use. You agree to use our
            Services only for legal, authorized, and acceptable purposes. You
            will not use (or assist others in using) our Services in ways that:
            (a) violate or infringe the rights of Sirkuni, our users, or others,
            including privacy, publicity, intellectual property, or other
            proprietary rights; (b) involve sending illegal or impermissible
            communications such as bulk messaging, auto-messaging, and
            auto-dialing. Harm to Sirkuni. You must not (or assist others to)
            access, use, modify, distribute, transfer, or exploit our Services
            in unauthorized manners, or in ways that harm Sirkuni, our Services,
            or systems. For example, you must not (a) gain or try to gain
            unauthorized access to our Services or systems; (b) disrupt the
            integrity or performance of our Services; (c) create accounts for
            our Services through unauthorized or automated means; (d) collect
            information about our users in any unauthorized manner; or (e) sell,
            rent, or charge for our Services. Keeping Your Account Secure.
            Sirkuni embraces privacy by design and does not have the ability to
            access your messages. You are responsible for keeping your device
            and your Sirkuni account safe and secure. If you lose your phone,
            follow the steps on our Support site to re-register for our
            Services. When you register with a new device, your old device will
            stop receiving all messages and calls. No Access to Emergency
            Services. Our Services do not provide access to emergency service
            providers like the police, fire department, hospitals, or other
            public safety organizations. Make sure you can contact emergency
            service providers through a mobile, fixed-line telephone, or other
            service. Third-party services. Our Services may allow you to access,
            use, or interact with third-party websites, apps, content, and other
            products and services. When you use third-party services, their
            terms and privacy policies govern your use of those services.
            <h1 className=" lh-1 mb-3">
              {" "}
              Your Rights and License with Sirkuni{" "}
            </h1>
            Your Rights. You own the information you submit through our
            Services. You must have the rights to the phone number you use to
            sign up for your Sirkuni account. Sirkuni Rights. We own all
            copyrights, trademarks, domains, logos, trade dress, trade secrets,
            patents, and other intellectual property rights associated with our
            Services. You may not use our copyrights, trademarks, domains,
            logos, trade dress, patents, and other intellectual property rights
            unless you have our written permission. To report copyright,
            trademark, or other intellectual property infringement, please
            contact appstore@insa.gov.et. Sirkuni License to You. Sirkuni grants
            you a limited, revocable, non-exclusive, and non-transferable
            license to use our Services in accordance with these Terms.
            <h1 className=" lh-1 mb-3"> Disclaimers and Limitations </h1>
            Disclaimers.SIRKUNI DOES NOT WARRANT THAT ANY INFORMATION PROVIDED
            BY US IS ACCURATE, COMPLETE, OR USEFUL, THAT OUR SERVICES WILL BE
            OPERATIONAL, ERROR-FREE, SECURE, OR SAFE, OR THAT OUR SERVICES WILL
            FUNCTION WITHOUT DISRUPTIONS, DELAYS, OR IMPERFECTIONS. WE DO NOT
            CONTROL, AND ARE NOT RESPONSIBLE FOR, CONTROLLING HOW OR WHEN OUR
            USERS USE OUR SERVICES. WE ARE NOT RESPONSIBLE FOR THE ACTIONS OR
            INFORMATION (INCLUDING CONTENT) OF OUR USERS OR OTHER THIRD PARTIES.
            YOU RELEASE US, AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES,
            PARTNERS, AND AGENTS (TOGETHER, “SIRKUNI PARTIES”) FROM ANY CLAIM,
            COMPLAINT, CAUSE OF ACTION, CONTROVERSY, OR DISPUTE (TOGETHER,
            “CLAIM”) AND DAMAGES, KNOWN AND UNKNOWN, RELATING TO, ARISING OUT
            OF, OR IN ANY WAY CONNECTED WITH ANY SUCH CLAIM YOU HAVE AGAINST ANY
            THIRD PARTIES. Limitation of liability. THE SIRKUNI PARTIES WILL NOT
            BE LIABLE TO YOU FOR ANY LOST PROFITS OR CONSEQUENTIAL, SPECIAL,
            PUNITIVE, INDIRECT, OR INCIDENTAL DAMAGES RELATING TO, ARISING OUT
            OF, OR IN ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SERVICES,
            EVEN IF THE SIRKUNI PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES. THE FOREGOING DISCLAIMER OF CERTAIN DAMAGES AND
            LIMITATION OF LIABILITY WILL APPLY TO THE MAXIMUM EXTENT PERMITTED
            BY APPLICABLE LAW. THE LAWS OF SOME STATES OR JURISDICTIONS MAY NOT
            ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL
            OF THE EXCLUSIONS AND LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO
            YOU. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN OUR TERMS, IN SUCH
            CASES, THE LIABILITY OF THE SIRKUNI PARTIES WILL BE LIMITED TO THE
            FULLEST EXTENT PERMITTED BY APPLICABLE LAW. Availability of Our
            Services. Our Services may be interrupted, including for
            maintenance, upgrades, or network or equipment failures. We may
            discontinue some or all of our Services, including certain features
            and the support for certain devices and platforms, at any time.
            <h1 className=" lh-1 mb-3">
              {" "}
              Resolving Disputes and Ending Terms{" "}
            </h1>
            Resolving disputes. You agree to resolve any Claim you have with us
            relating to or arising out of our Terms. Ending these Terms. You may
            end these Terms with Sirkuni at any time by deleting Sirkuni
            Messenger from your device and discontinuing use of our Services. We
            may modify, suspend, or terminate your access to or use of our
            Services anytime for any reason, such as if you violate the letter
            or spirit of our Terms or create harm, risk, or possible legal
            exposure for Sirkuni. The following provisions will survive
            termination of your relationship with Sirkuni: “Licenses,”
            “Disclaimers,” “Limitation of Liability,” “Resolving dispute,”
            “Availability” and “Ending these Terms,” and “General”.
            <h1 className=" lh-1 mb-3"> General </h1>
            Sirkuni may update the Terms from time to time. When we update our
            Terms, we will update the “Last Modified” date associated with the
            updated Terms. Your continued use of our Services confirms your
            acceptance of our updated Terms and supersedes any prior Terms. You
            will comply with all applicable export control and trade sanctions
            laws. Our Terms cover the entire agreement between you and Sirkuni
            regarding our Services. If you do not agree with our Terms, you
            should stop using our Services. If we fail to enforce any of our
            Terms, that does not mean we waive the right to enforce them. If any
            provision of the Terms is deemed unlawful, void, or unenforceable,
            that provision shall be deemed severable from our Terms and shall
            not affect the enforceability of the remaining provisions. Our
            Services are not intended for distribution to or use in any country
            where such distribution or use would violate local law or would
            subject us to any regulations in another country. We reserve the
            right to limit our Services in any country. If you have specific
            questions about these Terms, please contact us at
            appstore@insa.gov.et.
            <h1 className=" lh-1 mb-3"> Privacy Policy </h1>
            Sirkuni utilizes state-of-the-art security and end-to-end encryption
            to provide private messaging and Internet calling services to users
            worldwide (“Services”). Your calls and messages are always
            encrypted, so they can never be shared or viewed by anyone but
            yourself and the intended recipients.
            <h1 className=" lh-1 mb-3"> Information you provide</h1>
            Account Information. You register a phone number when you create a
            Sirkuni account. Phone numbers are used to provide our Services to
            you and other Sirkuni users. You may optionally add other
            information to your account, such as a profile name and profile
            picture. Messages. Sirkuni cannot decrypt or otherwise access the
            content of your messages or calls. Sirkuni queues end-to-end
            encrypted messages on its servers for delivery to devices that are
            temporarily offline (e.g., a phone whose battery has died). Your
            message history is stored on your own devices. Additional technical
            information is stored on our servers, including randomly generated
            authentication tokens, keys, push tokens, and other material that is
            necessary to establish calls and transmit messages. Sirkuni limits
            this additional technical information to the minimum required to
            operate the Services. Contacts. Sirkuni can optionally discover
            which contacts in your address book are Sirkuni users, using a
            service designed to protect the privacy of your contacts.
            Information from the contacts on your device may be
            cryptographically hashed and transmitted to the server in order to
            determine which of your contacts are registered. User Support. If
            you contact Sirkuni User Support, any personal data you may share
            with us is kept only for the purposes of researching the issue and
            contacting you about your case.
            <h1 className=" lh-1 mb-3">Information we may share</h1>
            Third Parties. We work with third parties to provide some of our
            Services. For example, our Third-Party Providers send a verification
            code to your phone number when you register for our Services. These
            providers are bound by their Privacy Policies to safeguard that
            information. If you use other Third-Party Services like YouTube,
            Spotify, Giphy, etc. in connection with our Services, their Terms
            and Privacy Policies govern your use of those services. Other
            instances where Sirkuni may need to share your data · To meet any
            applicable law, regulation, legal process or enforceable
            governmental request. · To enforce applicable Terms, including
            investigation of potential violations. · To detect, prevent, or
            otherwise address fraud, security, or technical issues. · To protect
            against harm to the rights, property, or safety of Sirkuni, our
            users, or the public as required or permitted by law.
            <h1 className=" lh-1 mb-3"> Updates </h1>
            We will update this privacy policy as needed so that it is current,
            accurate, and as clear as possible. Your continued use of our
            Services confirms your acceptance of our updated Privacy Policy.
            <h1 className=" lh-1 mb-3"> Terms</h1>
            Please also read our Terms which also governs the terms of this
            Privacy Policy.
            <h1 className=" lh-1 mb-3"> Contact Us</h1>
            appstore@insa.gov.et
            <p />
          </div>
        </div>
      </div>
    </div>
  </header>
  {/* Feedback Modal*/}
  <div
    className="modal fade"
    id="feedbackModal"
    tabIndex={-1}
    aria-labelledby="feedbackModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header bg-gradient-primary-to-secondary p-4">
          <h5
            className="modal-title font-alt text-white"
            id="feedbackModalLabel"
          >
            Send feedback
          </h5>
          <button
            className="btn-close btn-close-white"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body border-0 p-4">
          {/* * * * * * * * * * * * * * * **/}
          {/* * * SB Forms Contact Form * **/}
          {/* * * * * * * * * * * * * * * **/}
          {/* This form is pre-integrated with SB Forms.*/}
          {/* To make this form functional, sign up at*/}
          {/* to get an API token!*/}
          <form id="contactForm" data-sb-form-api-token="API_TOKEN">
            {/* Name input*/}
            <div className="form-floating mb-3">
              <input
                className="form-control"
                id="name"
                type="text"
                placeholder="Enter your name..."
                data-sb-validations="required"
              />
              <label htmlFor="name">Full name</label>
              <div
                className="invalid-feedback"
                data-sb-feedback="name:required"
              >
                A name is required.
              </div>
            </div>
            {/* Email address input*/}
            <div className="form-floating mb-3">
              <input
                className="form-control"
                id="email"
                type="email"
                placeholder="name@example.com"
                data-sb-validations="required,email"
              />
              <label htmlFor="email">Email address</label>
              <div
                className="invalid-feedback"
                data-sb-feedback="email:required"
              >
                An email is required.
              </div>
              <div className="invalid-feedback" data-sb-feedback="email:email">
                Email is not valid.
              </div>
            </div>
            {/* Phone number input*/}
            <div className="form-floating mb-3">
              <input
                className="form-control"
                id="phone"
                type="tel"
                placeholder="(123) 456-7890"
                data-sb-validations="required"
              />
              <label htmlFor="phone">Phone number</label>
              <div
                className="invalid-feedback"
                data-sb-feedback="phone:required"
              >
                A phone number is required.
              </div>
            </div>
            {/* Message input*/}
            <div className="form-floating mb-3">
              <textarea
                className="form-control"
                id="message"
                type="text"
                placeholder="Enter your message here..."
                style={{ height: "10rem" }}
                data-sb-validations="required"
                defaultValue={""}
              />
              <label htmlFor="message">Message</label>
              <div
                className="invalid-feedback"
                data-sb-feedback="message:required"
              >
                A message is required.
              </div>
            </div>
            {/* Submit success message*/}
            {/**/}
            {/* This is what your users will see when the form*/}
            {/* has successfully submitted*/}
            <div className="d-none" id="submitSuccessMessage">
              <div className="text-center mb-3">
                <div className="fw-bolder">Form submission successful!</div>
                To activate this form, sign up at
                <br />
                <a href="https://appstore.et">https://appstore.et</a>
              </div>
            </div>
            {/* Submit error message*/}
            {/**/}
            {/* This is what your users will see when there is*/}
            {/* an error submitting the form*/}
            <div className="d-none" id="submitErrorMessage">
              <div className="text-center text-danger mb-3">
                Error sending message!
              </div>
            </div>
            {/* Submit Button*/}
            <div className="d-grid">
              <button
                className="btn btn-primary rounded-pill btn-lg disabled"
                id="submitButton"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  {/* Quote/testimonial aside*/}
  {/* Bootstrap core JS*/}
  {/* Core theme JS*/}
  {/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * **/}
  {/* * *                               SB Forms JS                               * **/}
  {/* * * Activate your form at https://startbootstrap.com/solution/contact-forms * **/}
  {/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * **/}
  <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
      </Helmet>
      <Helmet>
      <script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script>
     

        
      </Helmet>
</>

    );
}
export default about;
